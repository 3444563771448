<template>
  <div class="dashboard-container m-8">
    <div class="hidden flex items-center justify-end print:hidden">
      <button class="flex text-right bg-transparent text-gray-500 py-1 px-4 mb-4 border border-gray-300 rounded-lg" @click="this.window.print()">
        <PrinterIcon class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5"/>Print
      </button>
    </div>
    <div class="mb-4 p-4 px-5 flex flex-col md:flex-row bg-gray-50 rounded-md text-gray-800">
      <dashboard-building-card
        class="flex-auto w-2/5"
        :buildingName="buildingName"
        :buildingAddress="buildingAddress"
        :showMenu="false"
      />
      <hr class="my-5 mt-7 md:hidden">
      <dashboard-community-data-card class="md:border-l md:pl-5 md:ml-5 flex-auto w-100 md:w-3/5"
        :updatedDate="latestBackgroundInfectionInformation?.date"
        learnMoreUrl="https://help.particleone.com/background-infection/"
        :backgroundInfection="latestBackgroundInfectionInformation?.percent"
      />
    </div>
    <button class="flex text-pink-600 hover:text-pink-700 mb-3 font-bold print:hidden" @click="$router.push({ name: 'DashboardSpaceSummary' })">
      <ChevronLeftIcon class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5"/>All spaces
    </button>
    <div class="mb-6 p-4 px-5 flex flex-col md:flex-row bg-gray-50 rounded-md text-gray-800">
      <div class="flex flex-col flex-auto w-2/5">
        <div class="flex items-baseline">
          <h4 class="text-2xl text-gray-800 font-bold">{{ this.selectedSpace?.name }}</h4>
          <Menu as="div" class="ml-2 relative">
            <MenuButton class="bg-gray-100 ml-auto p-0.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="h-4 w-4" />
            </MenuButton>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                  <MenuItem @click="userManagementModalOpen = true" as="button" class='text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer w-full' id="menu-item-0">Manage users</MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="mt-4 md:mt-auto">
          <p class="text-sm text-gray-800">{{this.selectedSpace?.description}}</p>
        </div>
      </div>
      <hr class="my-5 mt-6 md:hidden">
      <dashboard-risk-card
        class="flex-auto w-3/5"
        :risk="configuration?.risk"
        :lastUpdated="latestBackgroundInfectionInformation?.date"
      />

      <user-management
        :showModal="userManagementModalOpen"
        :name="selectedSpace?.name ?? ''"
        :buildingId="this.$route.params.buildingId"
        :spaceId="this.$route.params.spaceId"
        @close="userManagementModalOpen = false"
      />
    </div>
    <div class="tabs">
      <div class="sm:hidden mb-3 print:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" @change="selectTab($event)" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-pink-600 focus:border-pink-600 sm:text-sm rounded-md">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current" :value="tab.name">{{ tab.title }}</option>
        </select>
      </div>
      <div class="hidden sm:block print:hidden">
        <div class="border-b-2 border-gray-200">
          <nav class="-mb-0.5 flex space-x-4" aria-label="Tabs">
            <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            <button
              v-for="tab in tabs"
              :key="tab.name"
              @click="goToTab(tab)"
              class="hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-2 border-b-2 font-bold text-sm"
              :class="this.$route.name === tab.name ? 'border-pink-600 text-gray-900' : 'border-transparent text-gray-500'"
              :aria-current="this.$route.name === tab.name ? 'page' : undefined">
              {{ tab.title }}
            </button>
          </nav>
        </div>
      </div>
    </div>
    <div v-if="isLoading"></div>
    <div v-else class="bg-gray-50 rounded-b-lg print:hidden">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" @configuration-added="handleConfigurationAdded"></component>
        </keep-alive>
      </router-view>
    </div>
    <div class="invisible h-0 overflow-hidden print:visible print:h-auto print:overflow-auto">
      <div v-for="tab in tabsForPrinting" :key="tab.name">
        <h4 class="text-2xl text-gray-800 font-bold">{{tab.title}}</h4>
        <router-view :name="tab.name"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, PrinterIcon, DotsVerticalIcon } from '@heroicons/vue/solid';
import { mapGetters, useStore } from 'vuex';
import DashboardBuildingCard from './DashboardBuildingCard.vue';
import DashboardCommunityDataCard from './DashboardCommunityDataCard.vue';
import DashboardRiskCard from './DashboardRiskCard.vue';
import UserManagement from '@/components/user-management/UserManagement.vue';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { ref, reactive, computed } from '@vue/reactivity';

import buildingApiService from '@/network/services/building';
import { useRoute, useRouter } from 'vue-router';
import { watchEffect } from '@vue/runtime-core';

export default {
  name: 'DashboardSpaceDetailContainer',
  pageTitle() {
    return this.selectedSpace?.name ?? 'Loading...';
  },
  components: {
    ChevronLeftIcon,
    PrinterIcon,
    DashboardBuildingCard,
    DashboardCommunityDataCard,
    DashboardRiskCard,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    UserManagement
  },
  computed: {
    buildingName() {
      return this.selectedBuilding?.name;
    },
    buildingAddress() {
      return this.selectedBuilding?.address;
    },
    tabsForPrinting() {
      // Do not include Simulations tab when printing
      return this.tabs.filter(t => !t.title.toLowerCase().includes('simulation'));
    },
    latestBackgroundInfectionInformation() {
      const formattedInformation = this.backgroundInfectionInformation?.formattedInformation;
      if (formattedInformation == null) {
        return null;
      }
      return formattedInformation[formattedInformation.length - 1];
    },
    ...mapGetters(['selectedBuilding', 'selectedSpace', 'backgroundInfectionInformation'])
  },
  setup() {
    const tabs = [
      { name: 'DashboardSpaceOverview', title: 'Overview' },
      { name: 'DashboardSpaceSimulations', title: 'Simulations' },
      { name: 'DashboardSpaceDetails', title: 'Details' },
      { name: 'DashboardSpaceProductsAndSolutions', title: 'Products & solutions' },
    ];
    const userManagementModalOpen = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const buildingId = computed(() => Number(route.params.buildingId) );
    const spaceId = computed(() => Number(route.params.spaceId) );
    const isLoading = computed(() => {
      return spaceDetailData.data?.space == null ||
        configurationData.data?.configurations == null ||
        currentConfigurationData.data?.current_configuration === undefined ||
        scaledResultData.data?.scaled_data == null;
    });
    const configuration = computed(() => store.getters.currentConfiguration);

    const spaceDetailData = reactive({
      ...buildingApiService.getSpaceDetail(buildingId.value, spaceId.value),
    });
    const configurationData = reactive({
      ...buildingApiService.getConfigurations(spaceId.value)
    });
    const currentConfigurationData = reactive({
      ...buildingApiService.getCurrentConfiguration(spaceId.value)
    });
    const scaledResultData = reactive({
      ...buildingApiService.getScaledResults(spaceId.value, {})
    });

    watchEffect(() => {
      store.commit('updateSpace', spaceDetailData.data?.space);
      store.commit('updateBackgroundInfectionInformation', spaceDetailData.data?.space.background_infection);
    });

    watchEffect(() => {
      store.commit('updateConfigurations', configurationData.data?.configurations);
    });

    watchEffect(() => {
      store.commit('updateCurrentConfiguration', currentConfigurationData.data?.current_configuration);
    });

    watchEffect(() => {
      store.commit('updateScaledResults', scaledResultData.data?.scaled_data);
      store.commit('updateScaledResultsMetadata', scaledResultData.data?.metadata);
    });

    watchEffect(() => {
      store.commit('updateIsLoading', isLoading);
    });

    const unwatchInitialSelectedTab = watchEffect(() => {
      updateSelectedTab(store.getters.currentConfiguration, !isLoading.value);
    });

    function goToTab(tab, force = false) {
      force ? router.replace({ name: tab.name }) : router.push({ name: tab.name });
    }

    function updateSelectedTab(currentConfiguration, isDoneLoading) {
      if (isDoneLoading && !tabs.map(tab => tab.name).includes(route.name)) {
        goToTab(tabs[currentConfiguration?.risk ? 0 : 2], true);
      }

      if (isDoneLoading && unwatchInitialSelectedTab) {
        // Needed to stop propagating events when
        // attempting to navigate away from the page
        unwatchInitialSelectedTab();
      }
    }

    function handleConfigurationAdded(params) {
      if (scaledResultData.data && !(params.configurationId in scaledResultData.data.scaled_data)) {
        // Update the scaled results when a new configuration is added
        scaledResultData.mutate();
      }
    }

    return {
      tabs,
      userManagementModalOpen,
      isLoading,
      goToTab,
      configuration,
      handleConfigurationAdded
    };
  },
  methods: {
    selectTab(event) {
      const tab = this.tabs.find(tab => tab.name === event.target.value);
      if (tab) {
        this.goToTab(tab);
      }
    },
  }
};
</script>

<style>
</style>
